import { createRouter, createWebHistory } from 'vue-router';
import { initializeExperiments } from 'experiments-lib';
import { isEqualWith } from 'lodash';
import { nextTick } from 'vue';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { SearchParams } from './core/interfaces/search-params';
import { getSearchParamsFromLocationQuery } from './core/libs/search-params';
import { searchModule } from './core/modules/search-module';
import { orderInsensitiveArray } from './core/utils';
import { DEFAULT_EXPERIMENT_PARAMS, EXPERIMENT_CONFIG } from './experiments';
// import { CANONICAL_URLS, getCurrentLanguage } from './i18n';
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  // generateBreadCrumbs,
  generateHumanFriendlyDescription,
  generateHumanFriendlyTitle,
  getAbsoluteUrl,
  navigator as routeNavigator,
} from './navigator';

// Views.
import AboutPage from './views/AboutPage.vue';
import BrowserPage from './views/BrowserPage.vue';
import SignupPage from './views/SignupPage.vue';
import { withImpliedAspects } from './data/aspects-data';

export const BROWSER_ROUTE_NAME = 'browser';
export const VIEWER_ROUTE_NAME = 'viewer';

// This variable is used for detecting if the viewer has been accessed from the browser
// or directly from an external page. In case of an external page, the viewer will
// display a button that takes the user to the browser, as opposed to a back button.
export let browserAlreadyVisited = false;

interface RouteMetaParams {
  title: string | ((to: RouteLocationNormalized) => string);
  description: string | ((to: RouteLocationNormalized) => string);
}

const routes: Array<RouteRecordRaw> = [
  {
    // Main initial routing.
    path: '/',
    name: 'root',
    redirect: { name: BROWSER_ROUTE_NAME },
  },
  {
    path: '/feriencamps',
    name: BROWSER_ROUTE_NAME,
    component: BrowserPage,
    meta: {
      // @ts-ignore: they cannot follow too deep jsons somehow
      // title: (_) => i18n.global.t('meta.shop.title'),
      // @ts-ignore: they cannot follow too deep jsons somehow
      // description: (_) => i18n.global.t('meta.shop.description'),
      title: generateHumanFriendlyTitle,
      description: generateHumanFriendlyDescription,
    } as RouteMetaParams as any, // Type 'RouteMetaParams' is not comparable to type 'Record<string | number | symbol, unknown>'
  },
  {
    path: '/feriencamps/:canonicalId',
    name: VIEWER_ROUTE_NAME,
    component: () => import('./views/CampPage.vue'),
    meta: {
      // These will be updated from Viewer.vue once the camp data arrives.
      title: generateHumanFriendlyTitle,
      description: (_) => DEFAULT_DESCRIPTION,
    } as RouteMetaParams as any,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
    //   meta: {
    //     // These will be updated from Viewer.vue once the camp data arrives.
    //     title: generateHumanFriendlyTitle,
    //     description: DEFAULT_DESCRIPTION,
    //   } as RouteMetaParams as any,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    //   meta: {
    //     // These will be updated from Viewer.vue once the camp data arrives.
    //     title: generateHumanFriendlyTitle,
    //     description: DEFAULT_DESCRIPTION,
    //   } as RouteMetaParams as any,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ?? { top: 0 };
  },
});

function evalMetaField(
  to: RouteLocationNormalized,
  field: string | undefined | ((_: RouteLocationNormalized) => string)
): string | undefined {
  if (typeof field === 'string') {
    return field;
  } else if (typeof field === 'function') {
    return field(to);
  }
  return undefined;
}

function initializeSearchModuleFromUrl(
  experimentSearchParams: Partial<SearchParams>,
  route: RouteLocationNormalized
) {
  const searchParamsFromUrl = getSearchParamsFromLocationQuery(
    experimentSearchParams,
    route.query
  );
  searchParamsFromUrl.filters = withImpliedAspects(searchParamsFromUrl.filters);
  if (
    !isEqualWith(searchParamsFromUrl, searchModule.searchParams, orderInsensitiveArray)
  ) {
    // Perform the search.
    searchModule.doSearch(searchParamsFromUrl);
    // The UI will react to the route change in SideBar.vue.
  }
}

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const modifiedRoute = initializeExperiments(
    to,
    EXPERIMENT_CONFIG,
    DEFAULT_EXPERIMENT_PARAMS
  );
  // TODO: if (modifiedRoute !== false) { log active experiments }
  if (typeof modifiedRoute === 'object') {
    return modifiedRoute;
  }
  // TODO: add another redirect if we have initial query params.
  if (to.name === BROWSER_ROUTE_NAME) {
    browserAlreadyVisited = true;
    initializeSearchModuleFromUrl(/* experimentSearchParams= */ {}, to);
  }
});

router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    const meta = to.meta as unknown as RouteMetaParams;
    document.title = evalMetaField(to, meta.title) ?? DEFAULT_TITLE;
    document
      .querySelector('meta[name=description]')
      ?.setAttribute(
        'content',
        evalMetaField(to, meta.description) ?? DEFAULT_DESCRIPTION
      );
    const canonicalRoute = routeNavigator.linkToCanonical(to);
    if (canonicalRoute !== undefined) {
      document
        .querySelector('link[rel=canonical]')
        ?.setAttribute('href', getAbsoluteUrl(canonicalRoute));
    }
  });
});
