<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { HeartIcon as HeartIconOutline } from '@heroicons/vue/24/outline';
import { Bars3Icon } from '@heroicons/vue/24/solid';
import { onBeforeUnmount, onMounted, ref } from 'vue';
// import { favoritesModule } from '../modules/favorites-module';
// import About from './About.vue';
import parentaIcon from '../assets/icon.png';
import { useMq } from 'vue3-mq';
// import LanguagePicker from './LanguagePicker.vue';
import ParentaMenu from '../components/ParentaMenu.vue';
import SideBar from '../core/components/SideBar.vue';

const mq = useMq();

// let about = ref<typeof About | undefined>();
let mobileParentaSideBar = ref<typeof SideBar>();

let prevScrollpos = window.pageYOffset;
// let footer = ref<HTMLElement>();
let header = ref<HTMLElement>();

function handleScroll() {
  const topBar = document.getElementById('topbar');
  const headerHeight = topBar !== undefined ? topBar!.offsetHeight : 0;
  // const footerHeight = footer.value?.offsetHeight ?? 0;

  const currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    header.value!.style.top = '0';
    // footer.value!.style.bottom = '0';
  } else {
    header.value!.style.top = `-${headerHeight}px`;
    // footer.value!.style.bottom = `-${footerHeight}px`;
  }
  prevScrollpos = currentScrollPos;
}

onMounted(() => {
  header.value!.style.top = '0';
  // const footerHeight = footer.value?.offsetHeight ?? 0;
  // footer.value!.style.bottom = `-${footerHeight}px`;
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <header
    ref="header"
    class="sticky inset-x-0 z-10 flex flex-col items-stretch"
    :class="['mb-1 border-b border-slate-200 shadow-md']"
  >
    <nav
      id="topbar"
      class="z-5 grid h-14 max-w-4xl grid-cols-[auto,1fr,auto] items-center border-0 border-slate-300 bg-white"
    >
      <a href="https://parenta.ch/feriencamps" class="flex-shrink-0">
        <img
          class="h-8 w-auto px-2"
          width="143"
          height="64"
          :src="parentaIcon"
          alt="logo"
        />
      </a>

      <div
        class="flex flex-1 items-center justify-center gap-2 text-base font-bold md:text-lg"
      >
        <span>Parenta</span>
        <span>/</span>
        <span>Feriencamps</span>
        <span>/</span>
        <span v-if="!mq.mdPlus">Kt. ZH</span>
        <span v-else>Kt. Zürich</span>
      </div>

      <button
        v-if="!mq.mdPlus"
        @click="mobileParentaSideBar?.setIsOpen(true)"
        class=""
        aria-label="Menu"
      >
        <Bars3Icon class="h-10 w-auto px-2" />
        <SideBar v-if="!mq.mdPlus" ref="mobileParentaSideBar" :rightSide="true">
          <ParentaMenu :sideMenu="true" class="min-w-xs" />
        </SideBar>
      </button>
      <template v-else>
        <Popover class="relative">
          <PopoverButton aria-label="Menu">
            <Bars3Icon class="h-10 w-auto px-2" />
          </PopoverButton>

          <!-- Use the built-in `transition` component to add transitions. -->
          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <PopoverPanel class="absolute right-0 z-50">
              <ParentaMenu
                :sideMenu="false"
                class="min-w-xs border border-gray-400 bg-white shadow-xl"
              />
            </PopoverPanel>
          </transition>
        </Popover>
      </template>
    </nav>
    <slot></slot>
  </header>
</template>

<style scoped>
header {
  transition: top 0.3s;
}
</style>
