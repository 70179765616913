import { AspectId, FacetId } from 'search-backend';
import { AspectMetadata, FacetMetadata } from '../core/interfaces/aspects-metadata';

import aspectCanonicalizationMap from './aspect_canonicalization_map.json';
import aspects from './aspects.json';
import facets from './facets.json';

// GPT-3 prompts:
//
// 1. Consider this json. It contains entries that describe one feature of a holiday
//    camp for children. Extend the table where each row corresponds to one of these
//    aspects, and has columns like title and description, which correspond to a webpage
//    that lists camps with that feature.
//
// ...
//
//TABLE Feature |    Title        | Description
// ------------ | ----------- | ------------------- |
// fullDay      |  Camps providing full day activities   | This is a collection of
// children's holiday camps that provide full day activities.
//
//
// 2. This JSON is used for a website that provides information about children's holiday
//    camps. Translate title and description fields to German.
//

export const manual_aspects = {
  // dailyFormat
  fullDay: {
    facet: 'dailyFormat',
    displayText: 'ganztägig',
    metaTitle: 'Camps mit ganztägigen Aktivitäten ',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die ganztägige Aktivitäten anbieten.',
  },
  morning: {
    facet: 'dailyFormat',
    displayText: 'Vormittag',
    metaTitle: 'Camps mit Vormittagsaktivitäten',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die Aktivitäten am Vormittag anbieten.',
  },
  afternoon: {
    facet: 'dailyFormat',
    displayText: 'Nachmittag',
    metaTitle: 'Camps mit Nachmittagsaktivitäten',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die Aktivitäten am Nachmittag anbieten.',
  },
  hours: {
    facet: 'dailyFormat',
    displayText: 'einige Stunden',
    metaTitle: 'Camps mit einigen Stunden Aktivitäten',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die Aktivitäten für einige Stunden anbieten.',
  },
  overnight: {
    facet: 'dailyFormat',
    displayText: 'mit Übernachtung',
    metaTitle: 'Camps mit Übernachtungsmöglichkeiten',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die Übernachtungsmöglichkeiten anbieten.',
  },

  // genderRestriction
  girl: {
    facet: 'genderRestriction',
    displayText: 'für Mädchen',
    metaTitle: 'Nur für Mädchen Camps',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die nur für Mädchen bestimmt sind.',
  },
  boy: {
    facet: 'genderRestriction',
    displayText: 'für Buben',
    metaTitle: 'Nur für Buben Camps',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die nur für Buben bestimmt sind.',
  },

  // availability
  many: {
    facet: 'availability',
    displayText: 'viel',
    metaTitle: 'Camps mit vielen Plätzen verfügbar',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die viele Plätze verfügbar haben.',
  },
  few: {
    facet: 'availability',
    displayText: 'wenig',
    metaTitle: 'Camps mit wenigen Plätzen verfügbar',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die wenige Plätze verfügbar haben.',
  },
  waitlist: {
    facet: 'availability',
    displayText: 'Warteliste',
    metaTitle: 'Camps mit Warteliste',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die eine Warteliste für Plätze haben.',
  },
  full: {
    facet: 'availability',
    displayText: 'voll',
    metaTitle: 'Camps, die voll sind',
    metaDescription:
      'Dies ist eine Sammlung von Feriencamps für Kinder, die derzeit voll sind.',
  },
  unknown: {
    facet: 'availability',
    displayText: 'unbekannt',
    metaDescription: '',
  },

  // seasonal aspects
  summer: {
    facet: 'season',
    displayText: 'Sommer',
    metaTitle: 'Sommerferiencamps',
    metaDescription: 'Dies ist eine Sammlung von Sommerferiencamps für Kinder.',
  },
  winter: {
    facet: 'season',
    displayText: 'Winter',
    metaTitle: 'Winterferiencamps',
    metaDescription: 'Dies ist eine Sammlung von Winterferiencamps für Kinder.',
  },
  autumn: {
    facet: 'season',
    displayText: 'Herbst',
    metaTitle: 'Herbstferiencamps',
    metaDescription: 'Dies ist eine Sammlung von Herbstferiencamps für Kinder.',
  },
  spring: {
    facet: 'season',
    displayText: 'Frühling',
    metaTitle: 'Frühlingsferiencamps',
    metaDescription: 'Dies ist eine Sammlung von Frühlingsferiencamps für Kinder.',
  },
  easter: {
    facet: 'season',
    displayText: 'Ostern',
    metaTitle: 'Osterferiencamps',
    metaDescription: 'Dies ist eine Sammlung von Osterferiencamps für Kinder.',
  },
  // christmas: {
  //   facet: 'season',
  //   displayText: 'Weihnachten',
  //   metaTitle: 'Weihnachtsferiencamps',
  //   metaDescription: 'Dies ist eine Sammlung von Weihnachtsferiencamps für Kinder.',
  // },
};

export const ASPECTS = new Map<AspectId, AspectMetadata>(
  Object.entries({
    ...aspects,
    ...manual_aspects,
  })
);

const manual_facets = {
  dailyFormat: {
    displayText: 'Format',
  },
  genderRestriction: {
    displayText: 'Geschlecht',
  },
  availability: {
    displayText: 'Verfügbarkeit',
  },
};

export const FACETS = new Map<FacetId, FacetMetadata>(
  Object.entries({
    ...facets,
    ...manual_facets,
  })
);

// Any aspect seen here will be canonicalized to the aspect on the right in navigator.
export const ASPECT_CANONICALIZATION_MAP = new Map<AspectId, AspectId>(
  Object.entries(aspectCanonicalizationMap)
);

// Aspects in this map will be expanded to the implied ones on the right.
export const ASPECT_EXPLODE_MAP = new Map<AspectId, AspectId[]>(
  Object.entries(aspectCanonicalizationMap).reduce((acc, [key, value]) => {
    if (!acc.has(value)) {
      acc.set(value, []);
    }
    acc.get(value)!.push(key);
    return acc;
  }, new Map<AspectId, AspectId[]>())
);

export function withImpliedAspects(
  aspects: AspectId[] | undefined
): AspectId[] | undefined {
  // Is there a single aspect that is in ASPECT_EXPLODE_MAP?
  if (aspects && aspects.length == 1 && ASPECT_EXPLODE_MAP.has(aspects[0])) {
    return ASPECT_EXPLODE_MAP.get(aspects[0])!;
  }
  return aspects;
}
