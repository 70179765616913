import { AspectId, FacetId } from 'search-backend';
import { AspectMetadata, FacetMetadata } from '../core/interfaces/aspects-metadata';
import { VisualSelector } from '../core/interfaces/visual-selector';
import { ASPECTS, FACETS } from './aspects-data';

type AspectMetadataMap = Map<AspectId, AspectMetadata>;
type FacetMetadataMap = Map<FacetId, FacetMetadata>;

/************************************************************************
 * Exported functions, data and interfaces as part of the framework
 ************************************************************************/

// fetchAspects
//
// Loads the aspects and facets and returns a Promise for a pair of the respective maps.
//
// There is no restriction on how we load this data: it could happen as an import or
// through axios. It's better be fast though, as the search and ui is blocking on this
// data.
export async function fetchAspects(): Promise<[AspectMetadataMap, FacetMetadataMap]> {
  return [ASPECTS, FACETS];
}

// visibleFacets
//
// The facets that are shown in the filter menu.
//
// TODO: Move this into the facet metadata map and handle it in aspects module.
export const visibleFacets: FacetId[] = [
  'week',
  'class',
  'dailyFormat',
  //  'genderRestriction',
  //  'availability',
  'category',
  'inoutdoor',
  //  'arts_aspect',
  //  'crafts_aspect',
  //  'science_aspect',
  //  'sport_category',
  //  'sport_aspect',
  'location',
  'other_aspect',
];

export const VISUAL_SELECTORS: VisualSelector[] = [
  {
    // Time selector.
    title: 'message.visualSelector.when',
    color: '#d2ebf359',
    options: [
      // {
      //   title: 'message.visualSelector.sportFerien',
      //   image: {
      //     // https://unsplash.com/photos/5WIqleHzOok
      //     url: '/src/assets/winter.jpg',
      //     width: 284,
      //     height: 189,
      //   },
      //   aspects: ['20230206', '20230213', '20230220'],
      // },
      // {
      //   title: 'message.visualSelector.spring',
      //   image: {
      //     // https://unsplash.com/photos/hGNMJB7A33Q
      //     url: '/src/assets/spring.jpg',
      //     width: 284,
      //     height: 189,
      //   },
      //   aspects: ['20230424', '20230501'],
      // },
      // {
      //   title: 'message.visualSelector.summer',
      //   image: {
      //     // https://unsplash.com/photos/TxP44VIqlA8
      //     url: '/src/assets/summer.jpg',
      //     width: 284,
      //     height: 189,
      //   },
      //   aspects: ['20230717', '20230724', '20230731', '20230807', '20230814'],
      // },
      {
        title: 'message.visualSelector.fall',
        image: {
          // https://unsplash.com/photos/obKbq4Z3cuA
          url: '/src/assets/fall.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['20231009', '20231016'],
      },
      {
        title: 'message.visualSelector.otherDate',
        image: {
          // https://unsplash.com/photos/zHJ4ph3GRyg
          url: '/src/assets/otherdate.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['other_week'],
      },
    ],
  },

  {
    // Target audience selector.
    title: 'message.visualSelector.who',
    color: '#ffa5003b',
    options: [
      {
        title: 'message.visualSelector.kiga',
        image: {
          // https://unsplash.com/photos/Z1Oyw2snqn8
          url: '/src/assets/kiga.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['kiga'],
      },
      {
        title: 'message.visualSelector.unterstufe',
        image: {
          // https://unsplash.com/photos/FHFfHWWzbCc
          url: '/src/assets/unterstufe.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['unterstufe'],
      },
      {
        title: 'message.visualSelector.mittelstufe',
        image: {
          // https://unsplash.com/photos/8LITuYkZRIo
          url: '/src/assets/mittelstufe.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['mittelstufe'],
      },
      {
        title: 'message.visualSelector.older',
        image: {
          // https://unsplash.com/photos/KkoBekO8JtU
          url: '/src/assets/teens.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['sekundarstufe', 'adult'],
      },
    ],
  },

  {
    // Type selector.
    title: 'message.visualSelector.what',
    color: '#90ee9038',
    options: [
      {
        title: 'message.visualSelector.arts',
        image: {
          // https://unsplash.com/photos/Sj0nhVIb4eY
          url: '/src/assets/arts.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['arts'],
      },
      {
        title: 'message.visualSelector.crafts',
        image: {
          // https://unsplash.com/photos/LlX6BlViuUg
          url: '/src/assets/crafts.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['crafts'],
      },
      {
        title: 'message.visualSelector.sport',
        image: {
          // https://unsplash.com/photos/otYC4eoGJGg
          url: '/src/assets/sports.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['sport'],
      },
      {
        title: 'message.visualSelector.science',
        image: {
          // https://unsplash.com/photos/CgIFBwOkApI
          url: '/src/assets/science.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['science'],
      },
      {
        title: 'message.visualSelector.otherType',
        image: {
          // https://unsplash.com/photos/dAMvcGb8Vog
          url: '/src/assets/othertype.jpg',
          width: 284,
          height: 189,
        },
        aspects: ['other'],
      },
    ],
  },
];
