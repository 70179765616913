import { AspectId } from 'search-backend';
import {
  LocationQuery,
  LocationQueryValue,
  RouteLocationNormalized,
  RouteParams,
  RouteRecordName,
} from 'vue-router';
import { getSurfaceForm } from './core/interfaces/language';

import { SearchParams } from './core/interfaces/search-params';
import { getLocationQueryFromSearchParams } from './core/libs/search-params';
import { aspectsModule } from './core/modules/aspects-module';
import { BROWSER_ROUTE_NAME, router, VIEWER_ROUTE_NAME } from './router';
import { ASPECT_CANONICALIZATION_MAP } from './data/aspects-data';
import { Item } from './data/repo';

const ALIEN_PARAMS = new Set([
  // 'fbclk',  // keeping b/c analytics needs to track source and conversion events
  // 'gclid',  // keeping b/c analytics needs to track source and conversion events
  'kw',
  'pos',
  'loc_phys',
]);

const FILTER_PARAMS = new Set(['filter']);
const BASE_URL = 'https://parenta.ch';

interface ScratchRoute {
  name: RouteRecordName;
  query: LocationQuery;
  params: RouteParams;
}

function removeAlienQueryParams(query: LocationQuery): LocationQuery {
  // Removes any key from query params that is present in ALIEN_PARAMS.
  // Useful for striping Google Ads' params etc.
  return Object.fromEntries(
    Object.entries(query).filter(([key]) => !ALIEN_PARAMS.has(key))
  );
}

function removeFilterQueryParams(query: LocationQuery): LocationQuery {
  return Object.fromEntries(
    Object.entries(query).filter(([key]) => FILTER_PARAMS.has(key))
  );
}

export function getFirstStringParam(
  param: LocationQueryValue | LocationQueryValue[] | null
): string | undefined {
  if (typeof param == 'string') {
    return param;
  } else if (Array.isArray(param) && param.length > 0 && typeof param[0] == 'string') {
    return param[0];
  }
}

export function getAbsoluteUrl(to: ScratchRoute | RouteLocationNormalized) {
  return BASE_URL + router.resolve(to).href;
}

export class Navigator {
  linkToHome() {
    return { name: BROWSER_ROUTE_NAME };
  }

  getRouteWithSearchParams(searchParams: SearchParams) {
    return {
      name: BROWSER_ROUTE_NAME,
      query: {
        ...removeAlienQueryParams(router.currentRoute.value.query),
        ...getLocationQueryFromSearchParams(searchParams),
      },
    };
  }

  linkSplitFacetSingleCarousel(
    searchParams: SearchParams,
    aspectId: AspectId | AspectId[]
  ) {
    return {
      name: BROWSER_ROUTE_NAME,
      query: {
        ...removeAlienQueryParams(router.currentRoute.value.query),
        ...getLocationQueryFromSearchParams({
          ...searchParams,
          splitFacet: undefined,
          filters: [...(searchParams.filters ?? []), ...aspectId],
        }),
      },
    };
  }

  // This needs to be consistent with scripts/generate_sitemap.py.
  linkToCanonical(
    route: RouteLocationNormalized,
    camp: Readonly<Item> | undefined = undefined
  ): ScratchRoute | undefined {
    if (route.name == VIEWER_ROUTE_NAME) {
      if (camp !== undefined) {
        const filters = [camp.site];
        return {
          name: BROWSER_ROUTE_NAME,
          params: {},
          query: { filters },
        };
      } else {
        // This will be set once the camp data is loaded.
        return undefined;
      }
    } else if (route.name == BROWSER_ROUTE_NAME) {
      // The first selected aspect is the subcategory we canonicalize to.
      const firstAspect = getFirstStringParam(route.query.filters);
      const filters: string[] =
        firstAspect !== undefined
          ? [ASPECT_CANONICALIZATION_MAP.get(firstAspect) ?? firstAspect]
          : [];
      return {
        name: BROWSER_ROUTE_NAME,
        params: {},
        query: {
          filters,
        },
      };
      // } else if (route.name == 'privacy') {
      //   return {
      //     name: 'privacy',
      //     params: {},
      //     query: {},
      //   };
    } else {
      // Catch-all with the Store Browser.
      return {
        name: BROWSER_ROUTE_NAME,
        params: {},
        query: {},
      };
    }
  }

  linkToViewerWithoutFilters(route: RouteLocationNormalized): ScratchRoute {
    if (route.name == VIEWER_ROUTE_NAME) {
      return {
        name: VIEWER_ROUTE_NAME,
        params: {
          canonicalId: route.params.canonicalId,
        },
        query: removeFilterQueryParams(
          removeAlienQueryParams(router.currentRoute.value.query)
        ),
      };
    } else {
      return {
        name: BROWSER_ROUTE_NAME,
        params: {},
        query: removeFilterQueryParams(
          removeAlienQueryParams(router.currentRoute.value.query)
        ),
      };
    }
  }
}

export const DEFAULT_TITLE = 'Feriencamps';
export const DEFAULT_DESCRIPTION =
  'Erfahren Sie mehr über die Feriencamps in Kanton Zürich für die Schulferien zu Themen wie Sport, Kunst, Wissenschaft, Tiere, Theater, ...';

// This function is invoked twice. Initially, with no store name available, we put the
// domain into the title. We watch store data in the store page, and once it's ready,
// we call this function again and replace the title.
export function generateHumanFriendlyTitle(
  route: ScratchRoute | RouteLocationNormalized,
  campTitle: string | undefined
): string {
  if (route.name == VIEWER_ROUTE_NAME) {
    if (campTitle !== undefined) {
      return `Feriencamps – ${campTitle}`;
    } else {
      return DEFAULT_TITLE;
    }
  } else if (route.name == BROWSER_ROUTE_NAME) {
    const aspect = getFirstStringParam(route.query?.filters);
    const aspectMetaData = aspect ? aspectsModule.aspects.get(aspect) : undefined;
    return getSurfaceForm(aspectMetaData?.metaTitle) ?? aspect
      ? `Feriencamps – ${aspectsModule.getDisplayText(aspect!)}`
      : 'Feriencamps';
  }
  return DEFAULT_TITLE;
}

export function generateHumanFriendlyDescription(
  route: ScratchRoute | RouteLocationNormalized
): string {
  if (route.name == BROWSER_ROUTE_NAME) {
    const aspect = getFirstStringParam(route.query?.filters);
    const aspectMetaData = aspect ? aspectsModule.aspects.get(aspect) : undefined;
    return getSurfaceForm(aspectMetaData?.metaDescription) ?? DEFAULT_DESCRIPTION;
  }
  return DEFAULT_DESCRIPTION;
}

export const navigator = new Navigator();
