import { Arm, Layer, ParamValue } from 'experiments-lib';

export const DEFAULT_EXPERIMENT_PARAMS = {
  carouselFacet: 'none',
  singleColumnOnMobile: true,
  showPriceInBrowser: true,
  showAspectDescriptions: false,
  builtInViewer: false,
  invitingBottomSheet: false,
  useVisualSelectors: true,
};

type StrictParamName = keyof typeof DEFAULT_EXPERIMENT_PARAMS;
type StrictParams = Partial<Record<StrictParamName, ParamValue>>;
type StrictLayerConfig = Record<Arm, StrictParams>;
type StrictConfig = Record<Layer, StrictLayerConfig>;

export const EXPERIMENT_CONFIG: StrictConfig = {
  // Experiment layer with origin carousel with or without text (plus no carousel as
  // baseline).
  disabledCarouselWithDescription: {
    noCarousel: { carouselFacet: 'none', showAspectDescriptions: false },
    noText: { carouselFacet: 'origin', showAspectDescriptions: false },
    withText: { carouselFacet: 'origin', showAspectDescriptions: true },
  },
};
