import { createApp } from 'vue';
import App from './App.vue';
import { Vue3Mq } from 'vue3-mq';
import { initEventLogger } from './core/logging';
import { i18n } from './i18n';
import './main.css';
import { router } from './router';

declare function enableMetaPixel(): void;

const app = createApp(App).use(i18n).use(router).use(Vue3Mq, {
  preset: 'tailwind',
});
initEventLogger(app, 'de_ch').then(enableMetaPixel);

router.isReady().then(() => {
  app.mount('#app');
});
